import React from "react"
import { useLocation } from "@reach/router"
import { NavigationBar } from "../components/NavigationBar/parent"
import CookieBody from "../components/CookiePolicy/CookiePolicy"
import Footer from "../components/FooterComponents/Footer"
import {
  MainPageJson,
  PathJson,
  CookiePolicyJson,
} from "../components/location"
import Seo from "../components/SEOComponents/Seo"
import { LayoutTextPage } from "../components/LayoutTextPage/parent"

function CookiePolicy() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const CookiePolicyContent = CookiePolicyJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Title } = CookiePolicyContent.Header || {}

  return (
    <div className="maxwidth">
      <Seo title="cookie-policy" />
      <NavigationBar />
      <LayoutTextPage title={Title}>
        <CookieBody props={CookiePolicyContent} propsLink={PathContent} />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default CookiePolicy
