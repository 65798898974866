import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import styled from "styled-components"

function CookiePolicy({ props, propsLink }) {
  const { Header, Body1, Body2, Body3, Body4, Body5 } = props
  return (
    <>
      {/* POINT1 */}
      <p>
        <strong>{Body1.Title1}</strong>
      </p>
      <p>{Body1.Desc1}</p>
      <p>
        {Body1.Desc2}
        <a href="http://www.allaboutcookies.org/">{Body1.Desc2Link}</a>
      </p>
      <p>
        {Body1.Desc3}
        <Link to={propsLink.Service}>{Body1.Desc4}</Link>
        {Body1.Desc5}
      </p>
      {/* POINT2 */}
      <p>
        <strong>{Body2.Title1}</strong>
      </p>
      <p>{Body2.Desc1}</p>
      {/* POINT3 */}
      <p>
        <strong>{Body3.Title1}</strong>
      </p>
      <p>{Body3.Desc1}</p>
      <p>{Body3.Desc2}</p>
      <p>{Body3.Desc3}</p>
      {/* POINT4 */}
      <p>
        <strong>{Body4.Title}</strong>
      </p>
      <p>{Body4.Desc}</p>
      <CookieUl>
        <li>
          <p>
            <strong>
              {Body4.Point1}
              <em>{Body4.Point1a}</em>
            </strong>
          </p>
        </li>
        <p>{Body4.Desc1}</p>
        <p>
          <em>{Body4.Desc1a}</em>
        </p>
        <p>{Body4.Desc1b}</p>
        <ol
          type="1"
          style={{
            paddingInlineStart: "20px",
            fontFamily: "GothamBook",
          }}
        >
          <li style={{ marginBottom: "20px" }}>{Body4.Desc1c}</li>
          <li style={{ marginBottom: "20px" }}>{Body4.Desc1d}</li>
        </ol>
        <li>
          <p>
            <strong>
              {Body4.Point2}
              <em>{Body4.Point2a}</em>
            </strong>
          </p>
        </li>
        <p>{Body4.Desc2a}</p>
        <p>
          <em>{Body4.Desc2b}</em>
        </p>
        <p>{Body4.Desc2c}</p>
        <ol
          type="1"
          style={{
            paddingInlineStart: "20px",
            fontFamily: "GothamBook",
          }}
        >
          <li style={{ marginBottom: "20px" }}>{Body4.Desc2d}</li>
        </ol>
        <li>
          <p>
            <strong>
              {Body4.Point3}
              <em>{Body4.Point3a}</em>
            </strong>
          </p>
        </li>
        <p>{Body4.Desc3}</p>
        <p>
          <em>{Body4.Desc3a}</em>
        </p>
        <p>{Body4.Desc3b}</p>
        <ol
          type="1"
          style={{
            paddingInlineStart: "20px",
            fontFamily: "GothamBook",
          }}
        >
          <li style={{ marginBottom: "20px" }}>{Body4.Desc3c}</li>
          <li style={{ marginBottom: "20px" }}>{Body4.Desc3d}</li>
          <li style={{ marginBottom: "20px" }}>{Body4.Desc3e}</li>
          <li style={{ marginBottom: "20px" }}>{Body4.Desc3f}</li>
          <li style={{ marginBottom: "20px" }}>{Body4.Desc3g}</li>
          <li style={{ marginBottom: "20px" }}>{Body4.Desc3h}</li>
        </ol>
        <li>
          <p>
            <strong>
              {Body4.Point4}
              <em>{Body4.Point4a}</em>
            </strong>
          </p>
        </li>
        <p>{Body4.Desc4}</p>
        <p>
          <em>{Body4.Desc4a}</em>
        </p>
        <p>{Body4.Desc4b}</p>
        <ol
          type="1"
          style={{
            paddingInlineStart: "20px",
            fontFamily: "GothamBook",
          }}
        >
          <li style={{ marginBottom: "20px" }}>{Body4.Desc4c}</li>
        </ol>
        <li>
          <p>
            <strong>
              {Body4.Point5}
              <em>{Body4.Point5a}</em>
            </strong>
          </p>
        </li>
        <p>{Body4.Desc5}</p>
        <li>
          <p>
            <strong>{Body4.Point6}</strong>
          </p>
        </li>
        <p>{Body4.Desc6}</p>
      </CookieUl>
      {/* POINT5 */}
      <p>
        <strong>{Body5.Title}</strong>
      </p>
      <p>{Body5.Desc}</p>
    </>
  )
}

export default CookiePolicy

const CookieSection = styled.section`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

const CookieContainer = styled(Container)`
  color: #6c6365;
  max-width: 800px;
  > h1 {
    font-family: "GothamMedium";
    color: #48a9b5;
    text-align: center;
    margin-bottom: 3rem;
  }
  > p {
    font-family: "GothamBook";
    text-align: justify;
    color: #6c6365;
    > a {
      color: #48a9b5;
    }
  }
`

const CookieUl = styled.ul`
  padding-inline-start: 40px;

  > li {
    margin-bottom: 20px;
    font-family: "GothamBook";
    > p {
      font-family: "GothamBook";
    }
  }
  > p {
    font-family: "GothamBook";
  }
`
